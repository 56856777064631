
import { Compiler, Injectable } from '@angular/core';

import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Environment } from '../../environments/environment';
import { IRestResponse } from '../core/interface/response.interface';
import { ETenantType, EUserType } from '../enums/user.enum';
import { ILogin, ILoginWithOtpPayload, ILoginWithPwdPayload, IOtpToEmailPayload, IAdminOtpResponse } from '../interfaces/login.interface';
import { DataService } from './data.service';
import { RestService } from './rest.service';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private restService: RestService,
    private compiler: Compiler,
    private dataService: DataService,
    private router: Router) { }

  public forceLogout() {
    this.compiler.clearCache();
    // this.dataService.clearOut();
    localStorage.clear();
  }

  public login(data: ILoginWithOtpPayload | ILoginWithPwdPayload): Observable<IRestResponse<ILogin>> {
    return this.restService
      .oauthLogin(Environment.baseUrl + `/oauth/token`, data ,false).pipe(
        map((res: any) => res)
        , catchError((error) =>
          throwError(error))
      );
  }

  public sendLoginOtpToEmail(payload: IOtpToEmailPayload): Observable<IRestResponse<string | IAdminOtpResponse>> {
    return this.restService
      .post(Environment.UrlPath + `/company/login/otp`, payload).pipe(
        map((res: any) => res)
        , catchError((error) =>
          throwError(error))
      );
  }

  public logout(): Observable<IRestResponse<any>> {
    return this.restService
      .oauthLogout(Environment.baseUrl + `/oauth/logout`, false).pipe(
        map((res: any) => res)
        , catchError((error) =>
          throwError(error))
      );
  }
  get isLoggedIn() {
    return this.dataService.getLocalStorageItem('access_token') ? true : false
  }
  get isSuperAdmin() {
    return this.dataService.getLocalStorageItem('user_type') == EUserType.SUPER_ADMIN

  }
  get isUser() {
    return this.dataService.getLocalStorageItem('user_type') == EUserType.USER || this.dataService.getLocalStorageItem('user_type') == EUserType.COMPANY_ADMIN
  }
  get isSuperTenant() {
    return this.dataService.getLocalStorageItem('tenant_type') == ETenantType.SUPER_TENANT
  }
  get isTenant() {
    return this.dataService.getLocalStorageItem('tenant_type') == ETenantType.TENANT
  }
  get isSubTenant() {
    return this.dataService.getLocalStorageItem('tenant_type') == ETenantType.SUB_TENANT
  }

  get tenantId() {
    return this.dataService.getLocalStorageItem('tenant_id') 
  }

}
